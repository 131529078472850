<template>
  <div class="auth">
    <h1>Create Identity</h1>
    <h2>How would you like to identity yourself?</h2>
    <p>
      It is not necessary to write your real name, you can choose any that you like
    </p>
    <input type="text" placeholder="Type your name" class="auth__input" v-model="authName"/>
    <section class="avatars" v-if="images.length">
      <h2>Select your profile picture</h2>
<!--      {{images.length}}-{{authAvatar}}-{{authName}} - {{ !!(authAvatar && authName) }}-->
      <div class="avatars__list">
        <label v-for="image in images" :key="image" class="avatars__button">
          <input type="radio" name="avatar" v-model="authAvatar" :value="image" class="avatars__checkbox" />
          <span class="avatars__item">
            <span class="avatars__item_img-wrap">
              <img :src="image" class="avatars__item_img" @error="onImageError"/>
            </span>
<!--            <span class="avatars__item_subtitle">
              Subtitle
            </span>
            <span class="avatars__item_title">
              Title
            </span>-->
          </span>
        </label>
      </div>
    </section>
    <input type="submit" class="button" @click="onNextClick" :disabled="!(authAvatar && authName)" />
  </div>
</template>

<script>
// @ is an alias to /src

// import router from "@/router";

import api from "@/vtail/API";
import auth from "@/vtail/Auth";
import router from "@/router";
import {AVATARS64} from "@/vtail/avatars";

export default {
  name: 'Auth',
  components: {
  },
  data() {
    return {
      images:[],
      authName:'',
      authAvatar:'',
      meetID: this.$route.params.meetID
    }
  },
  mounted() {
    this.account = this.$route.params.account;
    console.info('Auth $route', this.$route.params);
    // api.alchemyGetImages('0x92f4937c03a5dd90f5382ea593c9f7f3ae1d23a5')
    // api.alchemyGetImages('0xcc602ea6c3aa34ead1773057d36544a5f724a63c')
    api.alchemyGetImages(this.account.id)
    .then(data=>{
      if (data.images.length){
        this.images = data.images;
      } else {
        this.authAvatar = 'default';
      }
      /*data.images.forEach(src=>{
        let img = new Image();
        img.onload = (image) => {
          console.info("onload", image)
          this.images.push(src);
        };
        img.onerror = (image) => {
          console.error("onerror", image)
          // this.images.push(src);
        };
        img.src = src;
      })*/
      console.info("alchemyGetImages", data)
    })
    .catch(e=>{
      console.error("alchemyGetImages", e)
    })
  },
  methods:{
    onImageError(e){
      console.info(e);
      let src = e.target.src
      e.target.src = src;
      /*const index = this.images.indexOf(e);
      if (index > -1) {
        this.images.splice(index, 1); // 2nd parameter means remove one item only
      }*/
      // e.target.remove();
    },
    onNextClick(){
      console.info("onNextClick", this.authName, this.authAvatar);

      let imagePromise;
      if (this.authAvatar === 'default') {
        imagePromise = {
          base64: AVATARS64[Math.floor(Math.random() * 4)]
        }
      } else {
        imagePromise = api.imagesToBase64({
          "url":    this.authAvatar,
          "height": 500,
          "width":  500
        })
      }

      console.info("DEFAULT", imagePromise);

      let authPromise = api.userAuthClient({
        store_user_id: this.account.id,
        signature: this.account.sign,
        secret_key : process.env.VUE_APP_VTAIL_API_SECRET_KEY,
        user_name: this.authName,
        is_guest: false,
      })

      Promise.all([authPromise, imagePromise])
          .then(([user, image]) => {
            console.info("All", user, image)
            user.avatar = image.base64;
            auth.setUser(user);
            if (this.meetID) {
              // router.push({name: 'MeetingPage', params: {meetID: this.meetID}});
              router.push({name: 'GetReadyPage', params: {meetID: this.meetID}});
            } else {
              router.push({name: 'CreateRoomPage'});
            }
          })
          .catch(error => {
            console.error("imagesToBase64", error)
          });

      /*api.imagesToBase64({
        "url": this.authAvatar,
        "height": 200,
        "width": 200
      })
          .then(data => {
            console.info("imagesToBase64", data)
          })
          .catch(error => {
            console.error("imagesToBase64", error)
          })*/

/*
      api.userAuthClient({
        store_user_id: this.account.id,
        signature: this.account.sign,
        secret_key : process.env.VUE_APP_VTAIL_API_SECRET_KEY,
        user_name: this.authName,
        is_guest: false,
      })
          .then(user => {
            console.info("userAuthClient", user);
            user.avatar = this.authAvatar;
            auth.setUser(user);
            // router.push({ name: 'MeetingPage', params: { meetID: this.$route.params.meetID} });
            router.push({ name: 'CreateRoomPage', params: { meetID: this.$route.params.meetID} });
          })
          .catch(error => {
            console.error("userAuthClient", error)
          })
      */
    },

  }
}
</script>
